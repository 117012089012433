html {
  height: auto;
}

body {
  background-image: url('/bg.png');
}

#app {
  margin-top: 1em;
}

#content {
  min-height: 500px;
  position: relative;
}

#footer {
  text-align: center;
  line-height: 0;
  opacity: 0.6;
}

#footer .item {
  font-size: 0.8rem !important;
}

#game div.tab {
  margin-top: 0.5em;
  margin-left: 0.5em;
}

.no-score {
  opacity: 0.5;
}

.score-container, .stat-container {
  overflow-x: auto;
}

.score-table, .stat-table {
  width: 100%;
  margin: 0;
  padding: 0;
  border-spacing: 0;
}

.score-table thead tr th, .stat-table thead tr th, .contract-table thead tr th {
  white-space: nowrap;
  margin-bottom: 0;
  padding-top: 5px;
  padding-bottom: 10px;
  border-bottom: 1px solid #ccc;
}

.score-table tbody tr td, .stat-table tbody tr td {
  padding: 10px;
  text-align: center;
  white-space: nowrap;
}

.score-table tbody tr:nth-child(even), .stat-table tbody tr:nth-child(even), .contract-table tbody tr:nth-child(even) {
  background-color: #e4e4e4;
}

.score-table tbody tr td:first-child, .stat-table tbody tr td:first-child {
  width: 1%;
}

.stat-table tbody tr td:first-child {
  text-align: left;
}

.score-round {
  font-weight: bold;
  padding: 5px 20px;
  cursor: pointer;
}

.score-round:hover {
  color: #1e70bf;
  text-decoration: underline;
}

.contract-table {
  margin: 0;
  padding: 0;
  border-spacing: 0;
}

.contract-table thead tr th {
  border-bottom: 1px solid #ccc;
}

.contract-table thead tr th {
  text-align: left;
  padding-top: 0;
  padding-bottom: 3px;
}

.contract-table tbody tr td {
  text-align: left;
  padding: 5px 3px;
  white-space: nowrap;
}

.contract-table tbody tr td input[type=number] {
  width: 75px;
}

.full-width {
  width: 100%;
}

.info-list-header {
  margin-bottom: 0;
}

.info-list {
  margin-top: 5px !important;
}

/*
  The default value is 8.01428571rem which only shows 3 items when expanded
  We want at least 6 to be shown, so updated the value
*/
@media only screen and (max-width: 767px) {
  .ui.selection.dropdown .menu {
    max-height: 16.02857143rem !important;
  }
}

/*
  The default value is 10.68571429rem which only shows 3 items when expanded
  We want at least 6 to be shown, so updated the value
*/
@media only screen and (min-width: 768px) {
  .ui.selection.dropdown .menu {
    max-height: 16.02857143rem !important;
  }
}
